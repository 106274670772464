import { useEffect, useRef, useState } from "react";
import "./Header.scss";
import { Container, Dropdown, Navbar } from "react-bootstrap";
import { Link, NavLink, useNavigate, useSearchParams } from "react-router-dom";
import logo from "../../../assets/images/logo.svg";
import lightLogo from "../../../assets/images/light-Logo.svg";
import { QRCode } from 'react-qrcode-logo';
import {
  DownArrowIcon,
  MoonIcon,
  SettingIcon,
  SunIcon,
  UnlockIcon,
  UserIcon,
  WalletIcon,
} from "../../../assets/Svg/SvgImages";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserInfo,
  getUserLayout,
  resetLayout,
  saveChangesGridLayout,
  saveChangesGridList,
} from "../../../features/persist/persistSlice";
import CustomDropdown from "../../ui/CustomDropdown/CustomDropdown";
import { ATS } from "../../../features/ats/atsSlice";
import NotificationList from "./NotificationList";
import { parseJSON, toFix } from "../../../helpers/commonHelper";
import { AnimatePresence, motion } from "framer-motion";
import { setTheme } from "../../../features/persist/persistSlice";
import { DEFAULT_LAYOUT } from "../../../constants/grid.layout";
// import { setTheme } from '../../../features/theme/theme.slice';

const Header = () => {
  const [queryParams] = useSearchParams();
  const [isActive, setIsActive] = useState(false);
  const toggleClass = () => {
    setIsActive(!isActive);
  };
  const current_breakpoint = useSelector(
    (state) => state.persist.current_breakpoint
  );
  const layoutList = useSelector((state) => state.persist.layoutList);
  const selectedPair = useSelector((state) => state.ats.selectedPair);
  const userInfo = useSelector(state => state?.persist?.userInfo);
  const activePairBalance = useSelector((state) => state.ats.activePairBalance);
  const theme = useSelector((state) => state.persist.theme);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const hanldeResetLayout = () => {
    dispatch(resetLayout());
  };
  const logout = async () => {
    const response = await dispatch(ATS.logout());
    if (response?.status === 200) {
      navigate("/auth/login");
    }
  };

  const options = ["Assets"];
  const fetchUserBalance = async () => {
    const obj = {
      baseCurrency: selectedPair?.baseCurrency,
      otherCurrency: selectedPair?.otherCurrency,
    };
    const response = await dispatch(ATS.fetchUserBalance(obj));
    console.log(response);
  };
  const fetchUserInfo = async () => {
    await dispatch(getUserInfo());
  };
  const fetchPairs = async () => {
    const pair = queryParams.get("symbol");
    await dispatch(ATS.fetchPairList(pair));
  };

  const handleChange = () => {
    dispatch(setTheme(theme === "dark" ? "light" : "dark"));
  };

  useEffect(() => {
    fetchPairs();
    fetchUserInfo();
  }, []);
  useEffect(() => {
    if (selectedPair) {
      fetchUserBalance();
    }
  }, [selectedPair]);

  const ref = useRef();
  const onclick = () => {
    if (ref.current && document.body.clientWidth < 11899) {
      ref.current.click();
    }
  };
  const onSelectSctions = async (type) => {
    console.log("Function Calling 1");
    const layouts = parseJSON(layoutList);
    const currentIndex = layouts.findIndex((o) => o.active);
    if (
      !layouts[currentIndex].layout["lg"].find((o) => o.i === type) &&
      !layouts[currentIndex].layout["md"].find((o) => o.i === type)
    ) {
      layouts[currentIndex].layout["lg"].push(
        parseJSON(DEFAULT_LAYOUT.lg.find((o) => o.i === type))
      );
      layouts[currentIndex].layout["md"].push(
        parseJSON(DEFAULT_LAYOUT.md.find((o) => o.i === type))
      );
      dispatch(saveChangesGridLayout(layouts[currentIndex].layout));
      dispatch(saveChangesGridList(layouts));
      await getUserLayout("PUT", { layouts });
    } else {
      console.log("Function Calling Else");
    }
  };

  return (
    <>
      <header className={isActive ? "siteHeader openmenu" : "siteHeader"}>
        <Navbar expand="xl">
          <Container fluid className="px-0">
            <div to="/" className="brandLogo">
              <img
                className="d-inline-block"
                src={theme === "light" ? lightLogo : logo}
                alt="logo"
              />
              {/* <img src={logo} alt="logo" className="d-inline-block" /> */}
            </div>
            <Navbar.Collapse className="justify-content-between">
              <div className="navLink d-xl-flex">
                <Link
                  onClick={onclick}
                  to="/"
                  className="brandLogo d-block d-xl-none"
                >
                  {/* <img src={logo} alt="logo" className="d-inline-block" /> */}
                  <img
                    className="d-inline-block"
                    src={theme === "light" ? lightLogo : logo}
                    alt="logo"
                  />
                </Link>
                <NavLink onClick={onclick} to="/portfolio" className="nav-link">
                  Portfolio
                </NavLink>
                <NavLink
                  onClick={onclick}
                  to={`/trade?symbol=${selectedPair?.pair_key}`}
                  className="nav-link"
                >
                  Trade
                </NavLink>
                <Dropdown className="navDropdown" align="start">
                  <Dropdown.Toggle variant="" id="dropdown-basic">
                    Market <DownArrowIcon />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Link
                      onClick={() => onSelectSctions("BuySellTab")}
                      to="javascript:void(0)"
                    >
                      Place Order
                    </Link>
                    <Link
                      onClick={() => onSelectSctions("Accounts")}
                      to="javascript:void(0)"
                    >
                      Accounts
                    </Link>
                    <Link
                      onClick={() => onSelectSctions("OrderBook")}
                      to="javascript:void(0)"
                    >
                      Orderbook
                    </Link>
                    <Link
                      onClick={() => onSelectSctions("TradeChart")}
                      to="javascript:void(0)"
                    >
                      Charts
                    </Link>
                    <Link
                      onClick={() => onSelectSctions("OpenOrder")}
                      to="javascript:void(0)"
                    >
                      Orders
                    </Link>
                    <Link
                      onClick={() => onSelectSctions("WatchList")}
                      to="javascript:void(0)"
                    >
                      Watch List
                    </Link>
                    <Link
                      onClick={() => onSelectSctions("MarketTrades")}
                      to="javascript:void(0)"
                    >
                      Market Trades
                    </Link>
                    <p>
                      Click on or Drag an item and Drop it to a place into the
                      layout
                    </p>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="headerFunds d-xl-flex align-center">
                <ul className="d-xl-flex">
                  <li>
                    <h6>Base Currency</h6>
                    <h6>
                      USD
                      {/* {selectedPair ? selectedPair.pair_key.split('_')[1].toUpperCase() : ''} */}
                    </h6>
                  </li>
                  <li>
                    <h6>Total Balance</h6>
                    <h6 className="green_text">
                      $
                      {activePairBalance
                        ? toFix(activePairBalance?.totalUsdBalance, 2)
                        : 0}
                    </h6>
                  </li>
                  <li>
                    <h6>Available Funds</h6>
                    <h6 className="green_text">
                      $
                      {activePairBalance
                        ? toFix(activePairBalance?.availableUsdBalance, 2)
                        : 0}
                    </h6>
                  </li>
                </ul>
                <div className="coinDropdown">
                  <CustomDropdown options={options} placeholder="Asset" />
                </div>
              </div>
            </Navbar.Collapse>
            {isActive && (
              <div
                onClick={onclick}
                className={`${isActive ? "active" : ""
                  } sidebar_backdrop d-xl-none`}
              />
            )}

            <div className="rightHeader d-flex align-items-center justify-content-between">
              <div className="userDropdown">
                <NotificationList />
              </div>
              <div className="NotificationDropdown">
                <Dropdown align="end">
                  <Dropdown.Toggle variant="" id="dropdown-basic">
                    <UserIcon />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {/* <Dropdown.Item onClick={onclick}>
                      <span>
                        <WalletIcon />
                      </span>{" "}
                      Connect Wallet
                    </Dropdown.Item> */}
                    <NavLink
                      className="dropdown-item"
                      to="/setting"
                      onClick={onclick}
                    >
                      <span>
                        <SettingIcon />
                      </span>{" "}
                      Settings
                    </NavLink>
                    <Dropdown.Item onClick={logout}>
                      <span>
                        <UnlockIcon />
                      </span>{" "}
                      Logout
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <div
                        onClick={onclick}
                        className="reset_btn text-center mt-3 w-100"
                      >
                        {userInfo ?
                          <QRCode value={`${userInfo.user_id}`} fgColor="#161a1e" logoImage="assets/logo_new.png" logoHeight={"40"} logoWidth={"40"} logoPadding={1} qrStyle="fluid" ecLevel="M" removeQrCodeBehindLogo={true} />
                          : <></>
                        }

                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <div
                        onClick={onclick}
                        className="reset_btn text-center mt-3 w-100"
                      >
                        <button
                          disabled={
                            current_breakpoint === "sm" ||
                              current_breakpoint === "xs"
                              ? true
                              : false
                          }
                          className="layoutResetbtn w-100"
                          onClick={hanldeResetLayout}
                        >
                          Reset Layout
                        </button>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <button
                className={`theme_btn ms-3 ms-md-4 ${theme === "dark" ? "active" : ""
                  }`}
                onClick={handleChange}
              >
                <motion.div layout>
                  <AnimatePresence mode="popLayout">
                    {theme === "dark" ? (
                      <motion.div
                        key={1}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <MoonIcon />
                      </motion.div>
                    ) : (
                      <motion.div
                        key={2}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <SunIcon />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.div>
              </button>
              <Navbar.Toggle ref={ref} onClick={toggleClass} />
            </div>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default Header;
